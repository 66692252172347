
<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:38:37
 * @LastEditTime: 2023-07-06 11:11
 * @Descripttion: 【作业考试-校内作业】批阅
-->
<style lang="scss" scoped>
.homework-read {
  @include innerPage;
  @include pageHead(-42px);

  .page-inner {
    width: 100%;
    height: calc(100% - 194px);
    margin-top: 32px;
    @include flexBox(space-between, flex-start);

    .students,
    .grade-box {
      width: 304px;
      height: 100%;
      border-radius: 10px;
      background: #fff;
      overflow: hidden;
    }

    .students {
      background: #f0f3fa;
      box-sizing: border-box;
      padding: 6px 10px 10px;

      .statistics-read {
        width: 100%;
        height: 122px;
        border-bottom: 1px solid #e2e4ea;
        position: relative;
        @include flexBox;

        p {
          width: 50%;
          height: 100%;
          line-height: 36px;
          font-size: 16px;
          color: #2a2a2a;
          flex-direction: column;
          @include flexBox(center);

          b {
            font-size: 22px;
            color: #6340c8;
          }

          span {
            margin-top: -2px;
          }

          &:first-child b {
            color: #28ca96;
          }
        }

        &:after {
          content: "";
          width: 1px;
          height: 14px;
          background: #7d7d7d;
          position: absolute;
          bottom: 37px;
          left: 50%;
          transform-origin: 0 0;
          transform: translateX(-50%) scaleX(0.5);
        }
      }

      ul {
        width: 100%;
        height: calc(100% - 162px);
        margin-top: 30px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 30px;

        .student {
          width: 100%;
          height: 80px;
          box-sizing: border-box;
          padding-top: 6px;
          padding-right: 24px;
          margin-bottom: 30px;
          cursor: pointer;
          @include flexBox;

          .el-avatar {
            border: 2px solid #c8c3e3;
            border-radius: 50%;
          }

          .read-info {
            width: calc(100% - 72px);
            height: 100%;
            margin-left: 10px;
            flex-direction: column;
            @include flexBox(space-around, flex-start);

            .student-info {
              width: 100%;
              height: 36px;
              margin-top: -4px;
              @include flexBox;

              span:not(.name) {
                flex-shrink: 0;
              }

              .name {
                margin-right: auto;
                font-size: 16px;
                color: #2b2b2b;
                flex-grow: 1;
              }

              .pf_bold {
                font-size: 20px;
                color: #ee4f65;
              }
            }

            .read-result {
              line-height: 20px;
              color: #6b6b6b;
            }
          }

          .will-read {
            font-size: 14px;
            color: #656565;
            @include flexBox;

            .iconfont {
              font-size: 24px;
              color: #c8c3e3;
              margin-right: 6px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.readed {
            padding-right: 0;
            align-items: flex-start;
          }

          &.reading {
            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }

            .el-avatar {
              border-color: #ee4f65;
            }
          }

          &:not(.readed):not(.reading):hover {
            transform: translateX(20px);

            .will-read {
              color: #ee4f65;

              .iconfont {
                color: #ee4f65;
              }
            }
          }
        }

        .no-data--empty {
          top: 30%;

          img {
            width: 160px;
          }
        }
      }
    }

    .grade-box {
      box-sizing: border-box;
      padding: 22px 6px 0;

      .form-wrapper {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding: 0 18px;
        overflow: hidden;
        overflow-y: auto;

        &.has-btns {
          height: calc(100% - 90px);
        }

        ::v-deep {
          .el-form {
            width: 100%;

            &-item {
              width: 100%;
              padding-bottom: 28px;
              position: relative;
              margin-bottom: 18px;
              border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);

              &.evaluation-type {
                position: relative;
              }

              .el-input__inner,
              .el-textarea__inner {
                background: #e9e9f4;
                padding: 0 20px;
              }

              .el-textarea__inner {
                min-height: 206px !important;
                padding: 24px 20px 14px;
              }

              &__label {
                font-family: "MicrosoftYaHei Bold";
                font-size: 16px;
                line-height: 36px;
                color: #303030;
              }

              &:first-child {
                .el-input__inner {
                  font-size: 24px;
                  color: #303030;
                  height: 52px;
                  line-height: 52px;
                }
              }

              &:nth-last-child(2),
              &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
              }

              .el-form-item {
                border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
                padding-bottom: 28px;
                margin-bottom: 18px;

                &:last-child {
                  padding-bottom: 0;
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }
            }
          }

          .label-right {
            position: absolute;
            top: 150px;
            right: 0;
            padding-right: 0;
            z-index: 2;

            &:nth-child(1) {
              top: 0;
            }

            .el-radio__label {
              color: #404040;
            }

            &::after {
              content: "\e63a";
              font-family: "iconfont";
              font-size: 12px;
              color: #fff;
              position: absolute;
              top: 50%;
              left: 3px;
              transform: translateY(-50%) scaleY(0);
              @include defaultAni;
            }

            &.is-checked::after {
              transform: translateY(-50%) scaleY(1);
            }
          }

          .el-radio-group {
            width: 100%;
          }

          .el-radio-button {
            width: 52px;
            height: 52px;
            border-radius: 10px;
            border: none;
            margin-right: 14px;

            &:last-child {
              margin-right: 0;
            }

            &__inner {
              width: 52px;
              height: 52px;
              line-height: 52px;
              font-size: 16px;
              text-align: center;
              padding: 0;
              border-radius: 10px;
              border: none;
              background: #e9e9f4;
            }

            &.is-active .el-radio-button__inner {
              background: #6340c8;
            }
          }

          .remark-group {
            width: calc(100% + 10px);
            margin-top: 26px;
            flex-wrap: wrap;
            position: relative;
            @include flexBox;

            .remark-item {
              max-width: 100%;
              height: 36px;
              margin: 0 10px 10px 0;
              box-sizing: border-box;
              padding: 0 16px;
              line-height: 36px;
              text-align: center;
              color: #6340c8;
              background: rgba($color: #6340c8, $alpha: 0.14);
              border-radius: 10px;
            }
          }

          .el-checkbox {
            &__inner {
              width: 20px;
              height: 20px;
            }

            &__label {
              color: #191919;
              font-size: 16px;
              margin-left: 6px;
            }
          }
        }

        .more-remark {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #6c4ecb;
          margin-bottom: 6px;
          cursor: pointer;

          &:hover {
            background: #8971d5;
          }

          .iconfont {
            font-size: 18px;
            text-align: center;
            line-height: 24px;
            color: #fff;
          }
        }

        .textarea {
          margin-top: 20px;
        }
      }

      .foot-btns {
        width: calc(100% - 36px);
        height: 80px;
        margin: 0 auto;
        position: relative;
        border-top: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
        @include flexBox(space-between);

        .el-button {
          flex-grow: 1;
          font-size: 16px;
          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 20px;
            background: #b5b5b5;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 0 0;
            transform: translateY(-50%) scaleX(0.5);
          }

          &:first-child::after {
            background: transparent;
          }
        }
      }
    }

    .read-box {
      width: calc(100% - 676px);
      height: 100%;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      .head {
        width: calc(100% - 92px);
        height: 124px;
        margin: 0 auto;
        border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
        box-sizing: border-box;
        padding: 38px 38px 0;

        h3 {
          line-height: 36px;
          text-align: center;
        }

        .data-info {
          height: 36px;
          margin-top: 4px;
          color: #676767;
        }
      }

      .drawing-opration {
        width: 868px;
        height: 98px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px 0;
        margin: 0 auto;
        @include flexBox(flex-end);

        button:not(.el-button) {
          width: 30px;
          height: 58px;
          border: none;
          background: transparent;
          outline: none;
          margin-left: 30px;
          flex-direction: column;
          cursor: pointer;
          @include flexBox(center);

          .iconfont {
            font-size: 30px;
            color: #8e8e8e;
          }

          span {
            color: #242323;
            margin-top: 6px;
          }
        }

        .picture--btns {
          height: 100%;
          margin-left: 20px;
          @include flexBox(center);

          .el-button {
            width: 42px;
            min-width: 42px;
            height: 42px;
            padding: 0;
            font-size: 20px;
            margin: 0;
            margin-bottom: 2px;
            margin-left: 10px;

            &--custom_primary {
              &.swiper-button-disabled {
                background-color: #c0afee;
                border-color: #b8a7e8;
                cursor: default;
              }
            }
          }
        }
      }

      .read-exercise {
        height: calc(100% - 124px);
        box-sizing: border-box;
        padding-right: 10px;
        padding-bottom: 10px;

        &.picture {
          height: calc(100% - 222px);
        }
      }
    }
  }
}

.canvas-img {
  width: 926px;
  position: fixed;
  top: 200%;
  left: 0;
  z-index: -1;
}


.question {
  width: 868px;
  box-sizing: border-box;
  padding: 30px 0;
  border-bottom: 1px solid rgba($color: #b5b5b5, $alpha: 0.24);
  @include flexBox;
  align-items: baseline;

  &-picture {
    width: 100%;

    .canvas-box {
      width: 100%;
      min-height: 240px;
      margin: 0 auto;
      position: relative;
    }
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 36px 0 46px;
    overflow: hidden;
    overflow-y: auto;
  }

  &--num,
  &--type {
    flex-shrink: 0;
    line-height: 24px;
    flex-shrink: 0;
  }

  &--num {
    color: #1f1f1f;
    font-size: 16px;
  }

  &--type {
    min-width: 56px;
    height: 24px;
    background: #6340c8;
    border-radius: 12px 0px 12px 12px;
    text-align: center;
    color: #fff;
    margin: 0 14px 0 4px;
    box-sizing: border-box;
    padding: 0 4px;
  }

  &--data {
    width: calc(100% - 100px);

    h5 {
      line-height: 24px;
      color: #1f1f1f;
    }

    .imgs-group {
      margin-top: 10px;
      @include flexBox;

      .el-image {
        width: 130px;
        height: 78px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }

    .options {
      margin-top: 14px;

      li {
        line-height: 30px;
        color: #666;

        .option--text {
          display: inline-flex;
          align-items: flex-start;
        }

        .imgs-group {
          margin-top: -4px;
          margin-bottom: 2px;
          box-sizing: border-box;
          padding-left: 52px;
        }

        &:last-child {
          .option--text {
            margin-bottom: 0;
          }

          .imgs-group {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  ::v-deep .el-radio {
    &__input {
      &:not(.is-disabled) {
        .el-radio__inner {
          &:hover {
            border-color: #2ac293;
          }
        }
      }

      &.is-checked {
        .el-radio__inner {
          border-color: #2ac293;
          background: #2ac293;
        }

        &+.el-radio__label {
          color: #2ac293;
        }
      }

      &__original:disabled {
        cursor: pointer;
      }
    }

    &:nth-child(2) {
      .el-radio__input:not(.is-disabled) {
        .el-radio__inner {
          &:hover {
            border-color: #f66478;
          }
        }

        &.is-checked {
          .el-radio__inner {
            border-color: #f66478;
            background: #f66478;
          }

          &+.el-radio__label {
            color: #f66478;
          }
        }
      }
    }
  }
}

.compose {
  &-wrapper {
    width: calc(100% + 70px);
    margin-left: -70px;
    margin-top: 18px;

    h5 {
      height: 38px;
      @include flexBox;

      span.bold {
        display: inline-block;
        flex-grow: 1;
        margin-right: 20px;
      }
    }

    .subtopic {
      margin-top: 20px;

      &-item {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        background: rgba($color: #eef0f8, $alpha: 0.4);
        border-radius: 10px;
        color: #666;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--title {
        font-size: 16px;
        line-height: 24px;
      }

      &--options {
        box-sizing: border-box;
        padding: 0 40px;
        margin-top: 16px;

        .option {
          line-height: 30px;

          &--text {
            margin-bottom: 16px;
            display: inline-flex;
            align-items: flex-start
          }

          .imgs-group {
            margin-top: -4px;
            margin-bottom: 2px;
            box-sizing: border-box;
            padding-left: 52px;
          }

          &:last-child {
            .option--text {
              margin-bottom: 0;
            }

            .imgs-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.answer {
  line-height: 24px;
  font-size: 14px;
  margin: 20px 0;
}

.stuanswer_image{
  width: 100px;
  height:150px;
  margin: 20px 0;
  border: 1px solid #DDDDDD;
  .el-image{
    width: 100%;
    height: 100%;
  }
}
.right-answer {
  width: 100%;
  min-height: 52px;
  background: #f0f3fa;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 30px;
  font-size: 16px;
  color: #393737;
  @include flexBox(space-between, center);

  span {
    @include flexBox;
    align-items: baseline;

    &:last-child {
      color: #2ac293;
    }
  }

  .iconfont {
    font-size: 20px;
    color: #ee4f65;
  }
}

.radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  border: solid 1px #dcdcdc;
  flex-shrink: 0;
  margin: 5px 14px 5px 0;
}

.wrong {
  .radio {
    border-color: #f66478;
    background: #f66478;
  }
}

.correct {
  .radio {
    border-color: #2ac293;
    background: #2ac293;
  }
}
</style>

<template>
  <section class="homework-read">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
      </div>
    </div>
    <div class="page-inner">
      <div class="students">
        <div class="statistics-read">
          <p>
            <b class="pf_bold">{{ studentsInfo.YesReadOver }}</b>
            <span>已批阅（份）</span>
          </p>
          <p>
            <b class="pf_bold">{{ studentsInfo.noReadOver }}</b>
            <span>未批阅（份）</span>
          </p>
        </div>
        <ul :class="{ 'no-data': $isEmpty(studentsInfo.data) }">
          <li class="student" :class="{
            readed: stu.sthom_wor_status == 30,
            reading: currentReadIndex == stu_index,
          }" v-for="(stu, stu_index) in studentsInfo.data" :key="stu.stuser_id" @click="changeStudent(stu_index)">
            <el-avatar fit="cover" :size="62" :src="formatfile(stu.stuser_image)" class="avatar">
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <div class="read-info">
              <!-- 已批阅 -->
              <template v-if="stu.sthom_wor_status == 30">
                <p class="student-info">
                  <span class="name line-text--1st"
                    :title="stu.stuser_name && stu.stuser_name.length > 3 && stu.stuser_name || ''">
                    {{ stu.stuser_name }}
                  </span>
                  <span>评分：</span>
                  <span class="pf_bold">
                    {{ stu.sthom_wor_show == 20 && stu.sthom_wor_grade || stu.sthom_wor_show == 10 && stu.sthom_wor_score
                    }}
                  </span>
                </p>
                <template v-if="stu.error_mark == 1">
                  <p class="read-result">错题标记：已完成</p>
                  <p class="read-result">错题数：{{ stu.error_count }}题</p>
                </template>
              </template>
              <!-- 未批阅 -->
              <template v-if="stu.sthom_wor_status != 30">
                <p class="student-info">
                  <span class="name line-text--1st"
                    :title="stu.stuser_name && stu.stuser_name.length > 3 && stu.stuser_name || ''">
                    {{ stu.stuser_name }}
                  </span>
                  <span class="will-read">
                    <i class="iconfont">&#xe61e;</i>待批阅
                  </span>
                </p>
              </template>
            </div>
          </li>
          <li class="no-data--empty" v-if="$isEmpty(studentsInfo.data)">
            <img src="@assets/images/no-data3.png" alt="">
            <p>暂无学生数据哦~</p>
          </li>
        </ul>
      </div>
      <div class="read-box" :class="{ 'no-data': $isEmpty(currentReadHomework) }">
        <template v-if="!$isEmpty(currentReadHomework)">
          <div class="head">
            <h3 class="bold line-text--1st">{{ currentReadHomework.sthom_wor_title || "-" }}</h3>
            <div class="data-info">
              <p class="line-text--1st">{{ currentReadHomework.sthom_wor_remark }}</p>
            </div>
          </div>
          <!-- 照片批阅操作按钮 -->
          <div class="drawing-opration" v-if="!$isEmpty(homeworkImgs)">
            <template v-if="!isReaded">
              <button @click="checkPrev" v-if="!$isEmpty(preDrawAry)">
                <i class="iconfont">&#xe647;</i>
                <span>撤销</span>
              </button>
              <button @click="checkClear">
                <i class="iconfont">&#xe648;</i>
                <span>清除</span>
              </button>
            </template>
            <div class="picture--btns">
              <el-button class="iconfont prev" type="custom_primary" size="mini"
                :disabled="currentImgIndex == 0 || p_loading" v-loading="p_loading" @click="changeImg('p')">
                &#xe612;
              </el-button>
              <el-button class="iconfont next" type="custom_primary" size="mini"
                :disabled="currentImgIndex == homeworkImgs.length - 1 || n_loading" v-loading="n_loading"
                @click="changeImg('n')">
                &#xe613;
              </el-button>
            </div>
          </div>
          <div class="read-exercise" :class="{ picture: !$isEmpty(homeworkImgs) }">
            <img class="canvas-img" ref="canvasImg" @load='canvasImgLoad = true' :src="formatfile(currentImg)" alt="" />
            <ul class="question-wrapper" ref="questionWrapper">
              <!-- 照片批阅画布 -->
              <li class="question-picture" v-show="!$isEmpty(homeworkImgs)">
                <div class="canvas-box" ref="canvasBox" v-loading="loadDrawing">
                  <canvas v-show="!loadDrawing" id="pictureCanvas" width="868" height="570"
                    @mousedown="canvasDown($event)" @mouseup="canvasUp($event)" @mousemove="canvasMove($event)"
                    @touchstart="canvasDown($event)" @touchend="canvasUp($event)"
                    @touchmove="canvasMove($event)"></canvas>
                </div>
              </li>
              <li class="question" :class="{ compose: item.syque_typ_id == 14 }"
                v-for="(item, que_index) in currentReadHomework.question" :key="item.sthom_que_id">
                <span class="question--num pf_bold">
                  {{
                    (que_index + 1 > 9 && que_index + 1) || `0${que_index + 1}`
                  }}.
                </span>
                <span class="question--type">{{ item.syque_typ_name }}</span>
                <div class="question--data">
                  <h5 class="bold richinline" v-html="item.sthom_que_title"></h5>
                  <div class="imgs-group" v-if="item.sthom_que_image">
                    <el-image fit="scale-down" :src="formatfile(item.sthom_que_image)"
                      :preview-src-list="[formatfile(item.sthom_que_image)]" />
                  </div>
                  <!-- 组合题 -->
                  <template v-if="item.syque_typ_id == 14">
                    <div class="compose-wrapper">
                      <ol class="subtopic">
                        <li class="subtopic-item" v-for="(s_item, s_index) in item.childrenQuestion"
                          :key="s_item.sthom_que_id">
                          <p class="subtopic--title">
                            （{{ s_index + 1 }}）【{{ s_item.syque_typ_name }}】<span class="richinline" v-html="s_item.sthom_que_title"></span>
                          </p>
                          <div class="imgs-group" v-if="s_item.sthom_que_image">
                            <el-image fit="scale-down" :src="formatfile(s_item.sthom_que_image)" :preview-src-list="[
                              formatfile(s_item.sthom_que_image),
                            ]" />
                          </div>
                          <template v-if="isChoice(s_item.syque_typ_id)">
                            <dl class="subtopic--options">
                              <dt class="option" v-for="o in s_item.question_item" :key="o.sthom_que_ite_id" :class="{
                                wrong:
                                  o.is_angser == 1 &&
                                  s_item.sthom_que_isstustatus == 20,
                                correct:
                                  o.is_angser == 1 &&
                                  s_item.sthom_que_isstustatus == 10,
                              }">
                                <span class="option--text">
                                  <i class="radio" v-if="$isEmpty(homeworkImgs)"></i>
                                  {{ o.sthom_que_ite_code }}.
                                  <span class="richinline" v-html="o.sthom_que_ite_title"></span>
                                </span>
                                <div class="imgs-group" v-if="o.sthom_que_ite_image">
                                  <el-image fit="scale-down" :src="formatfile(o.sthom_que_ite_image)" :preview-src-list="[
                                    formatfile(o.sthom_que_ite_image),
                                  ]" />
                                </div>
                              </dt>
                            </dl>
                            <div class="right-answer" v-if="s_item.sthom_que_isstustatus != 10 && $isEmpty(homeworkImgs)">
                              <span>
                                回答错误：
                                <i class="iconfont">&#xe63f;</i>
                              </span>
                              <span>正确答案：<span class="richinline" v-html="s_item.sthom_que_succ_answer"></span></span>
                            </div>
                          </template>
                          <template v-if="!isChoice(s_item.syque_typ_id) && $isEmpty(homeworkImgs)">
                            <p class="answer" v-if="!$isEmpty(s_item.sthom_que_stuanswer)">作答内容： <span class="richinline" v-html="s_item.sthom_que_stuanswer.join(',')"></span></p>
                            <!-- 手写板图片 -->
                            <div class="stuanswer_image" v-if="s_item.sthom_que_stuanswer_image">
                              <el-image class="el-image" :src="formatfile(s_item.sthom_que_stuanswer_image)" fit="cover" :preview-src-list="[formatfile(s_item.sthom_que_stuanswer_image)]"/>
                            </div>
                            <div class="answer-judgment">
                              <el-radio-group v-model="s_item.sthom_que_isstustatus" :disabled="isReaded"
                                @input="determineRight($event, s_item.sthom_que_id)">
                                <el-radio :label="10">正确</el-radio>
                                <el-radio :label="20">错误</el-radio>
                              </el-radio-group>
                            </div>
                          </template>
                        </li>
                      </ol>
                    </div>
                  </template>
                  <!-- 其他题型 -->
                  <template v-if="item.syque_typ_id != 14">
                    <!-- 选择题选项 -->
                    <template v-if="isChoice(item.syque_typ_id)">
                      <ul class="options">
                        <li :class="{
                          wrong:
                            o.is_angser == 1 &&
                            item.sthom_que_isstustatus == 20,
                          correct:
                            o.is_angser == 1 &&
                            item.sthom_que_isstustatus == 10,
                        }" v-for="o in item.question_item" :key="o.sthom_que_ite_id">
                          <span class="option--text">
                            <i class="radio" v-if="$isEmpty(homeworkImgs)"></i>
                            {{ o.sthom_que_ite_code }}.<span class="richinline" v-html="o.sthom_que_ite_title"></span>
                          </span>
                          <div class="imgs-group" v-if="o.sthom_que_ite_image">
                            <el-image fit="scale-down" :src="formatfile(o.sthom_que_ite_image)" :preview-src-list="[
                              formatfile(o.sthom_que_ite_image),
                            ]" />
                          </div>
                        </li>
                      </ul>
                      <div class="right-answer" v-if="item.sthom_que_isstustatus != 10 && $isEmpty(homeworkImgs)">
                        <span>
                          回答错误：
                          <i class="iconfont">&#xe63f;</i>
                        </span>
                        <span>正确答案：<span class="richinline" v-html="item.sthom_que_succ_answer"></span></span>
                      </div>
                    </template>
                    <!-- 非选择题 -->
                    <template v-if="!isChoice(item.syque_typ_id) && $isEmpty(homeworkImgs)">
                      <p class="answer" v-if="!$isEmpty(item.sthom_que_stuanswer)">作答内容：<span class="richinline" v-html="item.sthom_que_stuanswer.join(',')"></span> </p>
                      <!-- 手写板图片 -->
                      <div class="stuanswer_image" v-if="item.sthom_que_stuanswer_image">
                        <el-image class="el-image" :src="formatfile(item.sthom_que_stuanswer_image)" fit="cover" :preview-src-list="[formatfile(item.sthom_que_stuanswer_image)]"/>
                      </div>
                      <div class="answer-judgment">
                        <el-radio-group v-model="item.sthom_que_isstustatus" :disabled="isReaded"
                          @input="determineRight($event, item.sthom_que_id)">
                          <el-radio :label="10">正确</el-radio>
                          <el-radio :label="20">错误</el-radio>
                        </el-radio-group>
                      </div>
                    </template>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </template>
        <div class="no-data--empty" v-if="$isEmpty(currentReadHomework)">
          <img src="@assets/images/no-data3.png" alt="">
          <p>暂无作业数据哦~</p>
        </div>
      </div>
      <div class="grade-box">
        <div class="form-wrapper" :class="{ 'has-btns': !$isEmpty(studentsInfo.data) }">
          <el-form ref="gradeForm" :model="gradeForm" label-width="100%" label-position="top" :rules="gradeRules">
            <el-form-item class="evaluation-type">
              <!-- 评分 -->
              <template v-if="!isReaded || (gradeForm.sthom_wor_score + '')">
                <el-radio v-if="!isReaded" v-model="gradeForm.sthom_wor_show" :label="10"
                  class="label-right">优先展示</el-radio>
                <el-form-item label="评分" prop="sthom_wor_score">
                  <el-input v-model.trim="gradeForm.sthom_wor_score" maxlength="5" :readonly="isReaded"
                    @input="gradeForm.sthom_wor_score = iptInit(gradeForm.sthom_wor_score)"
                    @blur="gradeForm.sthom_wor_score = iptInitBlur(gradeForm.sthom_wor_score)" />
                </el-form-item>
              </template>
              <!-- 评级 -->
              <template v-if="!isReaded || (gradeForm.sthom_wor_grade + '')">
                <el-radio v-if="!isReaded" v-model="gradeForm.sthom_wor_show" :label="20"
                  class="label-right">优先展示</el-radio>
                <el-form-item label="评级" prop="sthom_wor_grade">
                  <el-radio-group v-model="gradeForm.sthom_wor_grade" :disabled="isReaded">
                    <el-radio-button label="A" value="A" />
                    <el-radio-button label="B" value="B" />
                    <el-radio-button label="C" value="C" />
                    <el-radio-button label="D" value="D" />
                  </el-radio-group>
                </el-form-item>
              </template>
            </el-form-item>
            <el-form-item label="评语" class="textarea">
              <el-input v-model.trim="gradeForm.sthom_wor_comment" type="textarea" maxlength="200" placeholder="请输入"
                :readonly="isReaded" />
              <!-- 评语列表 -->
              <div class="remark-group" v-if="!isReaded">
                <p class="remark-item line-text--1st" v-for="item in remarks.slice(0, 3)" :key="item.plrem_id"
                  @click="chooseRemark(item)">
                  {{ item.plrem_title }}
                </p>
                <el-dropdown class="more-remark" placement="bottom-start" v-if="remarks.length > 3">
                  <p class="iconfont">&#xe8aa;</p>
                  <el-dropdown-menu slot="dropdown" trigger="click">
                    <el-dropdown-item v-for="item in remarks.slice(3, remarks.length)" :key="item.plrem_id"
                      @click.native="chooseRemark(item)">
                      <p>{{ item.plrem_title }}</p>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
            <el-form-item v-if="!isReaded || gradeForm.sthom_wor_isexcellent">
              <el-checkbox v-model="gradeForm.sthom_wor_isexcellent">
                <span class="bold">优秀作业</span>
              </el-checkbox>
            </el-form-item>
          </el-form>
        </div>
        <div class="foot-btns" v-if="!$isEmpty(studentsInfo.data)">
          <el-button type="text" class="bold default" :disabled="currentReadIndex == 0"
            @click="changeStudent(currentReadIndex - 1)">上一份</el-button>
          <el-button type="text" class="bold default" :disabled="currentReadIndex == studentsInfo.data.length - 1"
            @click="changeStudent(currentReadIndex + 1)">
            下一份
          </el-button>
          <el-button type="text" class="bold default" v-if="!isReaded" :disablde="btnload" v-loading="btnload"
            @click="submitReadResult">
            提交
          </el-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  $getSchoolworkStus,
  $studentAnswerHomework,
  $studentAnswerSchoolwork,
  $determineHomeworkQues,
  $readHomeworkQues,
  $getRemarks
} from "@api/homeworkExam";
import { $uploadImg } from "@api/common";
import { formatFile, iptFloat } from "@utils";
export default {
  name: "he_homeworkRead",
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    },
    iptInit() {
      return function (val) {
        return iptFloat(val)
      }
    },
    iptInitBlur() {
      return function (val) {
        if (!val) return 0;
        if (val && val.split('.')[1] == '') return val.split('.')[0];
        return val;
      }
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断当前学生作业是否已完成批阅 */
    isReaded() {
      return this.currentReadHomework.sthom_wor_status == 30;
    },
  },
  data() {
    return {
      grade: null,
      class: null,
      homeworkId: null,
      studentsInfo: {}, // 学生信息（批阅）
      remarks: [], // 评语列表
      currentReadIndex: 0, // 当前批阅学生下标
      currentReadStu: {}, // 当前批阅学生信息
      currentReadHomework: {}, // 当前批阅作业信息
      homeworkImgs: [], // 拍照答题照片
      aboriginalImgs: [], // 拍照答题照片(备份未修改)
      currentImgIndex: 0,
      currentImg: "", // 当前展示答题图片
      gradeForm: {
        sthom_wor_show: 10,
        sthom_wor_score: 0,
      },
      gradeRules: {
        sthom_wor_score: [{ required: true, message: '请填写作业评分', trigger: "blur" }],
        sthom_wor_grade: [{ required: true, message: '请选择作业评级', trigger: "change" }],
      },
      btnload: false,
      p_loading: false,
      n_loading: false,
      /* 批阅图片（画布） */
      loadDrawing: false,
      canvas: null, //canvas标签id
      context: null, // canvas context
      canvasImg: null,
      canvasMoveUse: false, // 是否允许执行move时候绘制线条
      preDrawAry: [], //存储当前表面状态数组-上一步
      middleAry: [], //中间数组
      lineWidth: 1, // 线条宽度
      lineColor: "#fb4049", // 线条颜色
      pointX: 0,
      pointY: 0,
      scrollTop: 0,
    };
  },
  created() {
    let { id, homework } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    this.homeworkId = homework;
    this.getStudentsInfo();
    this.getRemarks();
  },
  mounted() {
    let timer = setInterval(() => {
      if (this.$refs.questionWrapper) {
        clearInterval(timer)
        this.$refs.questionWrapper.addEventListener("scroll", this.boxScroll);
      }
    }, 500);
  },
  methods: {
    /** 获取批阅学生信息 */
    async getStudentsInfo() {
      let { data: res } = await $getSchoolworkStus(this.homeworkId);
      this.studentsInfo = { ...res };
      this.currentReadStu = res.data[this.currentReadIndex];
      this.getReadInfo();
    },
    /** 获取评语列表 */
    async getRemarks() {
      let { data } = await $getRemarks(20);
      this.remarks = [...data];
    },
    /**
     * 选中评语
     * @param {object} data 评语数据 
     */
    chooseRemark(data) {
      this.gradeForm = {
        ...this.gradeForm,
        sthom_wor_comment: `${this.gradeForm.sthom_wor_comment || ''}${data.plrem_title || ''}`
      };
      this.$forceUpdate();
    },
    /** 切换学生作业信息 */
    changeStudent(index) {
      this.currentReadIndex = index;
      this.currentImgIndex = 0;
      this.currentReadStu = this.studentsInfo.data[index];
      this.gradeForm = {};
      this.$refs["gradeForm"].resetFields();
      this.gradeForm = {
        sthom_wor_show: 10,
        sthom_wor_score: 0,
      };
      this.getReadInfo();
    },
    /** 获取当前批阅作业信息 */
    async getReadInfo() {
      let id = this.currentReadStu.sthom_wor_id;
      let { data } = await $studentAnswerHomework(id);
      this.currentReadHomework = (this.$isEmpty(data) && {}) || { ...data };
      let imgArr = data.sthom_wor_status == 30 && data.sthom_wor_rating_image || data.sthom_wor_answerimg || [];
      this.homeworkImgs = this.$_.cloneDeep(imgArr);
      this.aboriginalImgs = this.$_.cloneDeep(imgArr);
      if (!this.$isEmpty(imgArr)) {
        this.currentImg = this.homeworkImgs[this.currentImgIndex];
        if (!this.canvas) {
          setTimeout(() => {
            this.createCanvas();
          }, 200);
        } else {
          this.drawImg();
        }
      }
      if (data.sthom_wor_status == 30) {
        this.gradeForm = {
          sthom_wor_grade: data.sthom_wor_grade,
          sthom_wor_score: data.sthom_wor_score,
          sthom_wor_show: data.sthom_wor_show,
          sthom_wor_comment: data.sthom_wor_comment || "无",
          sthom_wor_isexcellent: data.sthom_wor_isexcellent == 10,
        };
      }
      this.$nextTick(() => {
        this.$refs.questionWrapper.scrollTop = 0;
      })
      this.$forceUpdate();
    },
    /**
     * 提交非选择题批阅
     * @param {number} val 判定类型
     * @param {number} id 作业题目id
     */
    async determineRight(val, id) {
      await $determineHomeworkQues(id, val);
    },
    /** 提交批阅结果 */
    async submitReadResult() {
      if (!this.$isEmpty(this.homeworkImgs)) await this.checkSave();
      this.$refs.gradeForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let imgs = [];
          this.homeworkImgs.map(item => {
            imgs.push(formatFile(item));
          })
          let sthom_wor_isexcellent =
            (this.gradeForm.sthom_wor_isexcellent && 10) || 20;
          let params = {
            ...this.gradeForm,
            sthom_wor_isexcellent,
            sthom_wor_rating_image: imgs.join(","),
            sthom_wor_id: this.currentReadHomework.sthom_wor_id,
          };
          let res = await $readHomeworkQues(params);
          this.btnload = false;
          this.getStudentsInfo();
          if (res) return this.$message.success('提交成功')
        }
      });
    },
    /** 创建画布 */
    createCanvas() {
      //  获取canvas
      this.canvas = document.getElementById("pictureCanvas");
      if (!this.canvas) {
        return false;
      } else {
        this.loadDrawing = true;
        //  可以理解为一个画笔，可画路径、矩形、文字、图像
        this.context = this.canvas.getContext("2d");
        this.canvasImg = new Image();
        this.canvasImg.crossOrigin = "anonymous";
        this.canvasImg.src = this.currentImg;
        this.canvasImg.width = this.$refs.canvasImg.width;
        //  加载图片
        this.canvasImg.onload = () => {
          if (this.canvasImg.complete) {
            //  根据图像重新设定了canvas的长宽
            this.canvas.setAttribute("width", this.$refs.canvasImg.width);
            this.canvas.setAttribute("height", this.$refs.canvasImg.height);
            //  绘制图片
            this.context.drawImage(
              this.canvasImg,
              0,
              0,
              this.$refs.canvasImg.width,
              this.$refs.canvasImg.height
            );
          }
          setTimeout(() => {
            this.loadDrawing = false;
          }, 800);
        };
        this.canvasImg.onerror = () => {
          this.canvas.setAttribute("width", 0);
          this.canvas.setAttribute("height", 0);
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.loadDrawing = false;
        }
      }
    },
    /** 绘制图片 */
    drawImg() {
      this.canvasImg.src = this.currentImg;
      this.canvasImg.width = this.$refs.canvasImg.width;
      //  加载图片
      this.canvasImg.onload = () => {
        if (this.canvasImg.complete) {
          //  根据图像重新设定了canvas的长宽
          this.canvas.setAttribute("width", this.$refs.canvasImg.width);
          this.canvas.setAttribute("height", this.$refs.canvasImg.height);
          //  绘制图片
          this.context.drawImage(
            this.canvasImg,
            0,
            0,
            this.$refs.canvasImg.width,
            this.$refs.canvasImg.height
          );
        }
        setTimeout(() => {
          this.loadDrawing = false;
        }, 800);
      };
      this.canvasImg.onerror = () => {
        this.canvas.setAttribute("width", 0);
        this.canvas.setAttribute("height", 0);
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
    },
    /**
     * 图片切换
     * @param {string} type 切换类型
     */
    async changeImg(type) {
      this[`${type}_loading`] = true;
      if (!this.$isEmpty(this.preDrawAry)) {
        await this.checkSave();
        // 清空画布绘制
        this.preDrawAry = [];
        // middleAry恢复到默认数据
        this.middleAry = [this.middleAry[0]];
      }
      // 重置canvas画布
      this.context.clearRect(0, 0, 0, 0);
      if (type == "p") {
        this.currentImgIndex = this.currentImgIndex - 1;
      } else {
        this.currentImgIndex = this.currentImgIndex + 1;
      }
      this.currentImg = this.homeworkImgs[this.currentImgIndex];
      this.$nextTick(() => {
        this.loadDrawing = true;
        this.drawImg();
        this.$refs.questionWrapper.scrollTop = 0;
      })
      this[`${type}_loading`] = false;
    },
    /** 开始绘制 */
    beginPath(e) {
      if (this.isReaded) return;
      if (e.target !== this.canvas) {
        this.context.beginPath();
      }
    },
    /**
     * 鼠标按下
     * @param {*} e
     */
    canvasDown(e) {
      if (this.isReaded) return;
      // 让move方法可用
      this.canvasMoveUse = true;
      // client是基于整个页面的坐标
      // offset是cavas距离顶部以及左边的距离
      const canvasX = e.clientX - e.target.parentNode.offsetLeft;
      const canvasY = e.clientY - e.target.parentNode.offsetTop + this.scrollTop;
      // 设置canvas的配置
      this.context.lineWidth = this.lineWidth;
      this.context.strokeStyle = this.lineColor;
      this.context.beginPath(); //清除子路径
      // 移动的起点
      this.context.moveTo(canvasX, canvasY);
      //当前绘图表面状态
      const preData = this.context.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      //当前绘图表面进栈
      // 按下相当于新的操作的开始，所以把当前记录数据放到prev中
      this.preDrawAry.push(preData);
    },
    /**
     * 鼠标移动
     * @param {*} e
     */
    canvasMove(e) {
      if (this.isReaded) return;
      if (this.canvasMoveUse) {
        // 只有允许移动时调用
        const t = e.target;
        let canvasX;
        let canvasY;
        // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断
        if (this.isPc()) {
          canvasX = e.clientX - t.parentNode.offsetLeft;
          canvasY = e.clientY - t.parentNode.offsetTop + this.scrollTop;
        } else {
          canvasX = e.changedTouches[0].clientX - t.parentNode.offsetLeft;
          canvasY = e.changedTouches[0].clientY - t.parentNode.offsetTop;
        }
        // 连接到移动的位置并上色
        this.context.lineTo(canvasX, canvasY);
        this.context.stroke();
      }
    },
    /**
     * 鼠标松开
     */
    canvasUp() {
      const preData = this.context.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      if (this.preDrawAry.length < this.middleAry.length) {
        // 在没有撤销过的情况下，将当前数据放入prev
        // 当前绘图表面进栈
        this.middleAry.push(preData);
      } else {
        // 在撤销的情况下，将在后面步骤的数据情况记录
        this.middleAry = [...this.preDrawAry];
      }
      // 设置move时不可绘制
      this.canvasMoveUse = false;
    },
    /** 撤销 */
    checkPrev() {
      if (this.preDrawAry.length) {
        const popData = this.preDrawAry.pop();
        this.context.putImageData(popData, 0, 0);
      }
    },
    /** 清除 */
    checkClear() {
      let aboriginalImg = this.aboriginalImgs[this.currentImgIndex];
      console.log(aboriginalImg);
      this.homeworkImgs[this.currentImgIndex] = aboriginalImg;
      this.currentImg = aboriginalImg;
      // 清空数据
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.hei1ght);
      this.preDrawAry = [];
      // middleAry恢复到默认数据
      this.middleAry = [this.middleAry[0]];
      this.drawImg();
    },
    /** 保存批阅结果图片 */
    async checkSave() {
      let homeworkImgs = this.homeworkImgs;
      let base64Img = this.canvas.toDataURL("image/png");
      let imgFile = this.dataURLtoFile(base64Img);
      let { data } = await $uploadImg(imgFile);
      homeworkImgs[this.currentImgIndex] = data.address;
      this.homeworkImgs = [...homeworkImgs];
    },
    /**
     * 转换base64编码图片转换为file类型图片文件
     * @param {string} dataurl base64编码图片
     * @param {string} filename 文件类型
     */
    dataURLtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    /** 判断显示器类型 */
    isPc() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return !flag;
    },
    /** 页面滚动 */
    boxScroll() {
      this.scrollTop = this.$refs.questionWrapper.scrollTop;
    },
  },
};
</script>
